<template>
  <button
    class="rounded-md font-medium px-8 py-2 focus:outline-none transition-all duration-300"
    :class="dynamicCss"
    @click="click"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["click"],
  props: {
    secondary: Boolean,
    invalid: Boolean,
    negative: Boolean,
    alternative: Boolean
  }
})
export default class MButton extends Vue {
  secondary = false;
  invalid = false;
  negative = false;
  alternative = false;

  get dynamicCss() {
    const css: { [name: string]: boolean } = {};
    if (this.invalid) {
      css["bg-gray-300"] = true;
      css["text-white"] = true;
      css["cursor-default"] = true;
    } else {
      css["cursor-pointer"] = true;
      if (this.negative) {
        css["text-primary-500"] = true;
        css["bg-white"] = true;
        css["hover:bg-gray-200"] = true;
      } else if (this.alternative) {
        css["text-secondary-500"] = true;
        css["bg-white"] = true;
        css["border"] = true;
        css["border-secondary-500"] = true;
        css["hover:bg-gray-100"] = true;
      } else if (this.secondary) {
        css["bg-secondary-500"] = true;
        css["text-white"] = true;
        css["hover:bg-secondary-600"] = true;
      } else {
        css["bg-primary-500"] = true;
        css["text-white"] = true;
        css["hover:bg-primary-600"] = true;
      }
    }
    return css;
  }

  click() {
    this.$emit("click");
  }
}
</script>
